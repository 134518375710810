body {
  -webkit-font-smoothing: antialiased;
}      
::selection {
  color: #000000;
  background-color: #FFFFFF;
  }

.navbar.w-nav {
  opacity: 1 !important;
}